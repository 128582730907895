<template>
  <div class="home" v-if="showAppCenter">
    <div class="border"></div>
    <div class="cont">
      <ul>
        <li>
          <h4>我的应用</h4>
          <van-grid :border="false" square>
            <van-grid-item v-for="item in dataList" :key="item.id" @click="toApp(item.pageUrlH5, item.appId, item.appCode, item.appName)">
              <img :src="getImageStream(item.appIcon)" alt="" style="width:40px;height:40px">
              <p style="text-align:center;">{{item.appName}}</p>
            </van-grid-item>
          </van-grid>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {getImageStream} from '@/utils/index'
export default {
  data () {
    return {
      dataList: [],
      showAppCenter: false
    }
  },
  created () {
    this.getBackToken()
  },
  methods: {
    toApp (url, appId, code, name) {
      if (url.indexOf('/') == 0) {
        this.$router.push(url)
      } else if (url.indexOf('http') == 0) {
        this.$router.push({path: '/appSSO', query: {url, appId, code, name}})
      }
    },
    getImageStream (e) {
      return getImageStream(e)
    },
    getBackToken () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/appauth'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$globalData.backToken = data.token
          this.getList()
        } else {
          this.$message.error(data.msg)
          this.errorMsg = data.msg
        }
      })
    },
    getList () {
      this.$httpBack({
        url: this.$httpBack.adornUrl('/app/application/info/myApp'),
        method: 'get',
        params: this.$httpBack.adornParams({
          'page': 1,
          'limit': -1
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.showAppCenter = true
          this.dataList = data.page.list
        } else {
          this.dataList = []
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .van-grid-item__content {
  padding: 0;
}
.home {
  .fr {
    float: right !important;
  }
  .cont {
    overflow: hidden;
    // padding: 40px;
    background-color: #fff;
    ul {
      width: 100%;
      li {
        h4 {
          font-size: 30px;
          margin: 30px 44px;
          // margin-bottom: 30px;
        }
        .form-color {
          border-radius: 20px;
          position: relative;
          width: 100px;
          height: 100px;
        }
        .form-icon {
          color: #fff;
          font-size: 58px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        p {
          font-size: 26px;
        }
      }
    }
  }
}
.red {
  background-image: linear-gradient(135deg, #FF6161, #F53232);
}
.orange {
  background-image: linear-gradient(135deg, #F58166, #FC5B23);
}
.green {
  background-image: linear-gradient(135deg, #48DA97, #19BB65);
}
.blue {
  background-image: linear-gradient(135deg, #4C97FF, #2456EB);
}
.wathet {
  background-image: linear-gradient(135deg, #16B7FD, #2B95E7);
}
.light-orange {
  background-image: linear-gradient(135deg, #FFE36F, #FF7118);
}
.border {
  width: 100%;
  height: 20px;
  background-color: #f1f2f7;
}
</style>
